<template>
  <div>
<landing-page-header></landing-page-header>
     <router-view></router-view>
    <div />
  </div>
</template>

<script>
import LandingPageHeader from '@/components/LandingPageHeader'

export default {
    name:'About',
    components: { LandingPageHeader },

}
</script>
